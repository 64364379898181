import { Input } from "@Ignite-Reading/ui-kit/components";
import { useId } from "react";
import * as PropTypes from "prop-types";

import Spinner from "assets/icons/spinner.svg";
import MagnifyingGlass from "assets/icons/magnifying-glass.svg";

const SessionSearchInput = ({ isSearching, onChange, value }) => {
  const id = useId();

  return (
    <div className="justify-end flex items-center space-x-6">
      <div className="relative">
        <div
          aria-busy={isSearching}
          aria-label={isSearching ? "Searching" : ""}
          aria-labelledby={isSearching ? null : id}
          className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          role={isSearching ? "progressbar" : "img"}>
          {isSearching ? (
            <Spinner
              className="animate-spin h-4 w-4 text-brand-500"
              data-testid="searching-indicator"
            />
          ) : (
            <MagnifyingGlass className="h-5 w-5 text-gray-400" />
          )}
        </div>
        <Input
          aria-label="Search by tutor or student"
          className="pl-10 w-[240px]"
          name="search"
          id={id}
          placeholder="Search by tutor or student"
          type="search"
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

SessionSearchInput.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default SessionSearchInput;
