import { twcx } from "@Ignite-Reading/ui-kit/utils";
import { addMinutes, format, parse } from "date-fns";
import isNil from "lodash/isNil";
import * as PropTypes from "prop-types";

const TIME_FORMAT_TOKEN = "h:mm a";

const Attendee = ({
  displayName,
  email,
  label,
  minutesLate,
  minutesPresent,
  startTime,
}) => (
  <div className="pt-4 first:pt-0 flex items-center justify-between">
    <div className="space-y-1 grow">
      <div className="text-base text-zinc-500">{label}</div>
      {displayName ? (
        <h3
          className="font-semibold text-lg"
          data-testid={`${label}-name`}>
          {displayName}
        </h3>
      ) : (
        <div className="text-sm text-zinc-500 py-2">
          <em>No Tutor currently assigned</em>
        </div>
      )}
      {email ? (
        <div
          className="text-sm text-zinc-500"
          data-testid={`${label}-email`}>
          {email}
        </div>
      ) : null}
    </div>
    {!isNil(minutesLate) && !isNil(minutesPresent) ? (
      <div
        className={twcx(
          "border border-zinc-300 divide-y divide-zinc-200 rounded-lg basis-[160px] grid grid-cols-1 grid-rows-2 items-center text-center",
          {
            "grid-rows-3": minutesLate > 0,
          }
        )}
        data-testid={`${label}-attendance-block`}>
        <div
          className={twcx("py-1.5 font-semibold", {
            "text-green-700": minutesLate <= 0,
            "text-red-700": minutesLate > 0,
          })}
          data-testid="time-joined">
          {minutesLate > 0 ? "Late" : "On-time"}{" "}
          {format(
            addMinutes(
              parse(startTime, TIME_FORMAT_TOKEN, new Date()),
              minutesLate
            ),
            TIME_FORMAT_TOKEN
          )}
        </div>
        {minutesLate > 0 ? (
          <div className="py-1.5 text-xs text-zinc-500">
            Minutes Late
            <br />
            <span
              className="text-base text-zinc-700"
              data-testid="minutes-late">
              {minutesLate} min
            </span>
          </div>
        ) : null}
        <div className="py-1.5 text-xs text-zinc-500">
          Time spent in session
          <br />
          <span
            className="text-base text-zinc-700"
            data-testid="time-in-session">
            {minutesPresent} min
          </span>
        </div>
      </div>
    ) : null}
  </div>
);

Attendee.propTypes = {
  displayName: PropTypes.string,
  email: PropTypes.string,
  label: PropTypes.string.isRequired,
  minutesLate: PropTypes.number,
  minutesPresent: PropTypes.number,
  startTime: PropTypes.string,
};

export default Attendee;
