import { Table } from "@Ignite-Reading/ui-kit/components";
import noop from "lodash/noop";
import * as PropTypes from "prop-types";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { BASE_10 } from "constants";
import ConnectionStatusTooltip from "./ConnectionStatusTooltip";
import useRequestPolling from "./useRequestPolling";

const RealTimeUpdatingTable = ({
  children,
  columns: columnsFromProps,
  noDataMessage,
  onSettled = noop,
  pageSearchKey,
  paginationAriaLabel,
  pollingInterval,
  pollingPromise,
  pollingPromiseParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get(pageSearchKey) ?? 1, BASE_10);
  const onChangePage = useCallback(
    page => {
      setSearchParams(prev => ({
        ...Object.fromEntries(prev.entries()),
        [pageSearchKey]: page,
      }));
    },
    [pageSearchKey, setSearchParams]
  );
  const { data, hasError, pagination, lastUpdated, status } = useRequestPolling(
    {
      interval: pollingInterval,
      params: pollingPromiseParams,
      promise: pollingPromise,
      onSettled,
    }
  );
  const columns = [
    ...columnsFromProps,
    ...(lastUpdated
      ? [
          {
            "aria-label": "Last updated",
            id: "actions",
            label: (
              <ConnectionStatusTooltip
                lastUpdated={lastUpdated}
                status={status}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      data={data}
      errorMessageClassName="static translate-x-0 sm:translate-x-0 left-auto sm:left-auto w-full"
      hasError={hasError}
      noDataMessage={noDataMessage}
      noDataMessageClassName="static translate-x-0 sm:translate-x-0 left-auto sm:left-auto w-full"
      pagination={{
        "aria-label": paginationAriaLabel,
        count: pagination?.pages || 1,
        onChange: onChangePage,
        page: currentPage,
      }}>
      {data => data.map(row => children(row, columns.length))}
    </Table>
  );
};

RealTimeUpdatingTable.propTypes = {
  children: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentPage: PropTypes.number,
  noDataMessage: PropTypes.string.isRequired,
  onSettled: PropTypes.func,
  pageSearchKey: PropTypes.string.isRequired,
  paginationAriaLabel: PropTypes.string.isRequired,
  pollingInterval: PropTypes.number.isRequired,
  pollingPromise: PropTypes.func.isRequired,
  pollingPromiseParams: PropTypes.object,
};

export default RealTimeUpdatingTable;
