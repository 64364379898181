import { twcx } from "@Ignite-Reading/ui-kit/utils";
import PropTypes from "prop-types";

import FaceRetouchingOffIcon from "assets/icons/face-retouching-off.svg";
import SessionMonitorIcon from "assets/icons/session-monitor.svg";
import CheckCircleIcon from "assets/icons/check-circle-outline.svg";
import SwapCallsIcon from "assets/icons/swap-calls.svg";

export const LEFT = "left";
export const NOT_PRESENT = "not_present";
export const JOINED = "joined";
export const SUBBED = "subbed";

const statusToIconMap = {
  [LEFT]: FaceRetouchingOffIcon,
  [NOT_PRESENT]: FaceRetouchingOffIcon,
  [JOINED]: CheckCircleIcon,
};

const PresencePill = ({
  children,
  "data-testid": dataTestId = null,
  hideStatusDot = false,
  href = null,
  isSessionMonitor = false,
  isSub = false,
  status = NOT_PRESENT,
}) => {
  const PresenceIcon =
    (isSessionMonitor
      ? SessionMonitorIcon
      : isSub
        ? SwapCallsIcon
        : statusToIconMap[status]) || FaceRetouchingOffIcon;
  const RootElement = href !== null ? "a" : "div";
  const props =
    href !== null ? { href, rel: "noopener noreferrer", target: "_blank" } : {};

  return (
    <RootElement
      className={twcx(
        "flex items-center border-[1.5px] border-zinc-400 rounded-full pl-1 pr-1 py-0.5",
        {
          "border-brand-900 bg-brand-900": status === JOINED,
          "border-zinc-700 bg-zinc-700": status === JOINED && isSessionMonitor,
          "hover:bg-brand-700": href !== null && status === JOINED,
        }
      )}
      data-testid={dataTestId}
      {...props}>
      {!hideStatusDot ? (
        <div
          className={twcx("w-[10px] h-[10px] rounded-full bg-red-600 mx-1.5", {
            "bg-green-500": status === JOINED,
            "bg-yellow-400": status === LEFT,
          })}
          data-testid={dataTestId ? `${dataTestId}-status-dot` : null}
        />
      ) : null}
      {children ? (
        <p
          className={twcx("text-sm first:pl-1.5 pr-1.5 whitespace-nowrap", {
            "text-white/90": status === JOINED,
          })}
          data-testid={dataTestId ? `${dataTestId}-children` : null}>
          {children}
        </p>
      ) : null}
      <div className="flex items-center justify-center h-[20px] w-[20px] p-[1.5px] only:mx-0.5">
        <PresenceIcon
          className={twcx("w-full h-full text-zinc-500", {
            "text-white/90": status === JOINED,
          })}
        />
      </div>
    </RootElement>
  );
};

PresencePill.propTypes = {
  "data-testid": PropTypes.string,
  children: PropTypes.node,
  hideStatusDot: PropTypes.bool,
  href: PropTypes.string,
  isSessionMonitor: PropTypes.bool,
  isSub: PropTypes.bool,
  status: PropTypes.oneOf([LEFT, NOT_PRESENT, JOINED, SUBBED]),
};

export default PresencePill;
