import { usePolling } from "@Ignite-Reading/ui-kit/hooks";
import noop from "lodash/noop";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { PollingStatus } from "constants";

const useRequestPolling = ({
  interval = 5000,
  params,
  promise,
  retries = 5,
  requestTimeout = 5000,
  onSettled = noop,
}) => {
  const abortControllerRef = useRef(null);
  const requestTimeoutRef = useRef(null);
  const retryCountRef = useRef(retries);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [status, setStatus] = useState(PollingStatus.CONNECTING);
  const [lastUpdated, setLastUpdated] = useState(null);
  const callback = useCallback(
    async ({ pause }) => {
      abortControllerRef.current = new AbortController();
      try {
        requestTimeoutRef.current = setTimeout(() => {
          abortControllerRef.current.abort();
        }, requestTimeout);

        const response = await promise({
          ...params,
          signal: abortControllerRef.current.signal,
        });

        clearTimeout(requestTimeoutRef.current);
        if (response.status === 200) {
          setStatus(PollingStatus.OPEN);
          setData(response.data);
          setPagination(response.pagination);
          setLastUpdated(new Date());
        } else {
          throw new Error(response);
        }
      } catch (e) {
        setStatus(PollingStatus.CLOSED);
        if (retryCountRef.current === 0) {
          pause();
          setHasError(true);
        }
        retryCountRef.current -= 1;
      } finally {
        onSettled();
        abortControllerRef.current = null;
      }
    },
    [onSettled, params, promise, requestTimeout]
  );

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);
  usePolling(callback, interval);

  return useMemo(() => {
    return {
      data,
      hasError,
      pagination,
      lastUpdated,
      status,
    };
  }, [data, hasError, lastUpdated, pagination, status]);
};

export default useRequestPolling;
